// import bootstrap from 'bootstrap'
// import { Modal, Dropdown } from "bootstrap"; 
import React, { useState } from 'react'
import teleprompter from './teleprompter.js'

import logo from './images/logo512.png'
import deepfile from './images/deepfilesearch.jpg'
import techstack from './images/tech.jpg'
import subtrsrt from './images/sub-trans-srt.png'
import lib from './images/lib.jpg'
import stack from './images/stacker.jpg'
import sim from './images/sim.jpg'
import collect from './images/c2n.jpg'
// import gtues from './images/gtues.jpg'
import calex from './images/calex.jpg'

function App () {
    // window.Modal = Modal;
    // window.Dropdown = Dropdown;
    function darkMode0(){
        if(isDarkMode0){
            document.getElementById('daddy').setAttribute('data-bs-theme', 'light')
            setDarkMode0(false)
            // document.getElementById('darkMode0').innerHTML = '🌙 Dark Mode'
            // document.getElementById('darkMode0').classList.remove("btn-dark")
            // document.getElementById('darkMode0').classList.add("btn-light")
        } else {        
            document.getElementById('daddy').setAttribute('data-bs-theme', 'dark')
            setDarkMode0(true)
            // document.getElementById('darkMode0').innerHTML = '🌞 Light Mode'
            // document.getElementById('darkMode0').classList.remove("btn-light")
            // document.getElementById('darkMode0').classList.add("btn-dark")
        }
    }

    const today = new Date();
    const year = today.getFullYear();
    const [lang,setLang] = useState("en")
    const [page,setPage] = useState("home")
    const [isDarkMode0, setDarkMode0] = useState(true)
    const langs = {
        'fi':'🇫🇮 | Suomi',
        'ru':'🇷🇺 | Русский',
        'es':'🇪🇸 | Español',
        'de':'🇩🇪 | Deutsch',
        'en':'🇺🇸 | English'
    }
    const textsArr = [
        {
            'fi':'innovatiivinen teknologia konsultointi',
            'ru':'консультации по инновационному технологиям',
            'es':'consultoría de tecnológica innovadora',
            'de':'innovative Technologieberatung',
            'en':'innovative technology consultancy'
        },
        {
            'fi':'koti',
            'ru':'дом',
            'es':'la casa',
            'de':'startseite',
            'en':'home'
        },
        {
            'fi':'meiän tiimi',
            'ru':'наша команда',
            'es':'nuestro equipo',
            'de':'unser mannschaft',
            'en':'our team'
        },
        {
            'fi':'vapaat ohjelmistot',
            'ru':'свободный софт',
            'es':'programas gratis',
            'de':'freie software',
            'en':'free software'
        },
        {
            'fi':'',
            'ru':'',
            'es':'',
            'de':'',
            'en':'get in contact'
        },
        {
            'fi':'yhy/spc llc on liikeyritys näiden verkkosivustojen ja koodin takana',
            'ru':'yhy/spc llc — это бизнес, стоящий за разработкой следующих веб-сайтов и кода',
            'es':'yhy/spc llc es el negocio detrás del desarrollo de estos códigos y páginas red',
            'de':'yhy/spc llc ist das unternehmen hinter der entwicklung der folgenden websites und codes',
            'en':'yhy/spc llc is the business behind the development of the following websites and code'
        },
        {
            'fi':'jös sulla on GDPR/DMCA toivomus, tai erikoinen kysymys, lähetä meille viesti: contact@yhy.fi',
            'ru':'если у вас есть вапрос GDPR/DMCA или какой-либо другой вопрос, отправьте нам сообщение по адресу: contact@yhy.fi',
            'es':'si usted tenga una requesta de GDPR/DMCA, o algo otra preguntaba, mándanos mensaje a: contact@yhy.fi',
            'de':'wenn sie eine GDPR/DMCA-anfrage oder eine andere frage haben, senden sie uns eine nachricht an: contact@yhy.fi',
            'en':'if you have a GDPR/DMCA request, or some other question, send us a message at: contact@yhy.fi'
        },
        {
            'fi':'ohjata kaikki laskutuskysymykset osoitteeseen: billing@yhy.fi',
            'ru':'отправляйте все вапросы по оплате на: billing@yhy.fi',
            'es':'envia sus preguntas facturaciónes a: billing@yhy.fi',
            'de':'senden sie alle rechnungsanfragen an: billing@yhy.fi',
            'en':'forward all billing inquiries to: billing@yhy.fi'
        },
        {
            'fi':`Alex on pääohjelmoija.  Vapaa-ajallaan hän haluaa auttaa ystäviään ja tehdä koodeja tehdä enemmän koko maailmalle.`,
            'ru':`Алексу это наша главное программисиом.  В свободное время, он любит помогать их други и сделать коды чтобы делате больше всё миру.`,
            'es':`Alex es nuestro programador principal.  En su tiempo libre, le gusta ayudar a sus amigos a crear código para hacer más en el mundo.`,
            'de':`Alex ist unser Hauptprogrammierer.  In seiner Freizeit hilft er seinen Freunden gerne beim Programmieren, um mehr in der Welt zu tun.`,
            'en':`Alex is our main programmer.  In his free time, he likes to help his friends make code to do more in the world.`,
        },
    ]

    return (
        <div className="container">
            <div className="container text-left">
                <div className="row align-items-center">
                    <div className="col-6">
                        <img src={logo} className="d-block w-100 p3" alt="YHY/SPC Logo" />
                    </div>
                    <div className="col-6 p-3">
                        YHY/SPC LLC<br/>
                        {textsArr[0][lang]}<br/><br/>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {langs[lang]}
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={()=>{setLang('fi')}} href="#">🇫🇮 | Suomi</a></li>
                                <li><a className="dropdown-item" onClick={()=>{setLang('ru')}} href="#">🇷🇺 | Русский</a></li>
                                <li><a className="dropdown-item" onClick={()=>{setLang('es')}} href="#">🇪🇸 | Español</a></li>
                                <li><a className="dropdown-item" onClick={()=>{setLang('de')}} href="#">🇩🇪 | Deutsch</a></li>
                                <li><a className="dropdown-item" onClick={()=>{setLang('en')}} href="#">🇺🇸 | English</a></li>
                            </ul>
                        </div>
                        <br/>
                        <button type="button" id="darkMode0" onClick={()=>darkMode0()} className={isDarkMode0?'btn btn-dark':'btn btn-light'}>{isDarkMode0?`🌞 Light Mode`:`🌙 Dark Mode`}</button>
                    </div>
                </div>
                <br/><br/>
            </div>
            {/* accordion color set #151879 */}
            <div className="accordion" id="myAccordion">
                <div className="accordion-item">
                    <h2 className="accordion-header" style={{'--bs-primary-text-emphasis':'#FFFFFF'}}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {textsArr[1][lang]}
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                    <div className="accordion-body">
                        <div className='container text-center'>
                            {textsArr[5][lang]}
                        </div>
                        <br/>
                        <div id="myCarousel" className="carousel slide">
                            <div className="carousel-inner">
                                <div className="carousel-item">
                                <img src={deepfile} className="d-block w-100" alt="deep-file-search" />
                                </div>
                                <div className="carousel-item active">
                                <img src={techstack} className="d-block w-100" alt="Fluent Technologies" />
                                </div>
                                <div className="carousel-item">
                                <img src={subtrsrt} className="d-block w-100" alt="Subtitle Translator" />
                                </div>
                                <div className="carousel-item">
                                <img src={lib} className="d-block w-100" alt="Link-In.Bio" />
                                </div>
                                <div className="carousel-item">
                                <img src={stack} className="d-block w-100" alt="C++ Pallet Stacker" />
                                </div>
                                <div className="carousel-item">
                                <img src={sim} className="d-block w-100" alt="Store Inventory Manager" />
                                </div>
                                <div className="carousel-item">
                                <img src={collect} className="d-block w-100" alt="Collection-2-NFT" />
                                </div>
                                {/* <div className="carousel-item">
                                <img src={gtues} className="d-block w-100" alt="Giving-Tuesday.net" />
                                </div> */}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        <br/>
                        <div className='container text-center'>
                            {textsArr[6][lang]}
                            <br />
                            {textsArr[7][lang]}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" style={{'--bs-primary-text-emphasis':'#FFFFFF'}}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        {textsArr[2][lang]}
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                    <div className="accordion-body">
                        <div className="row align-items-start">
                            <div className="col-6">
                                <img src={calex} className="d-block w-100 p3" alt="Photo of Alex" />
                            </div>
                            <div className="col-6 p-3">
                                <strong>C. Alex</strong><br/>
                                <a href="tel:+14149497728" alt="Phone Number">+1 (414) YHY-SPC-8</a><br />
                                <a href="mailto:alex@yhy.fi" alt="mail">alex@yhy.fi</a>
                                <br/>{textsArr[8][lang]}<br/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" style={{'--bs-primary-text-emphasis':'#FFFFFF'}}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        {textsArr[3][lang]}
                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                    <div className="accordion-body">
                        <ul>
                            <li><strong><a href="https://link-in.bio" alt="Link-In.Bio">Link-In.Bio</a></strong></li>
                            <li><strong><a href="https://papaya-pastelito-06b03a.netlify.app" alt="Collection-2-NFT">Collection-2-NFT</a></strong></li>
                            <li><strong><a href="https://gitlab.com/calexh/spam-detector-ai" alt="AI Spam Detector">AI Spam Detector</a></strong></li>
                            <li>Custom Blockchains</li>
                            <li>NFT-Generator</li>
                            <li><strong><a href="https://www.npmjs.com/package/sub-trans-srt" alt="Sub-Trans-Srt">NPM SRT File Subtitle Translator</a></strong></li>
                            <li><strong><a href="https://www.npmjs.com/package/deep-file-search" alt="Deep-File-Search">NPM Deep File Searcher</a></strong></li>
                            <li><strong><a href="https://www.npmjs.com/package/quick-teleprompter" alt="Quick-Teleprompter">NPM Quick Teleprompter</a></strong></li>
                            <li><strong><a href="https://www.npmjs.com/package/linkedin-crawler" alt="LinkedIn-Crawler">NPM LinkedIn-Crawler</a></strong></li>
                            <li><strong><a href="https://www.npmjs.com/package/author-total-downloads" alt="Author-Total-Downloads">NPM Author-Total-Downloads</a></strong></li>
                            <li><strong><a href="https://www.npmjs.com/package/big-stringify" alt="Big-Stringify">NPM Big-Stringify</a></strong></li>
                            <li>🔜 NPM Rhyming Dictionary (Multi-Language)</li>
                            <li>🔜 NPM Working-React-Native-Picker</li>
                            <li>🔜 C++ Pallet Stacker</li>
                            <li>🔜 NPM MP3 BPM Analysis</li>
                            <li>🔜 Store Inventory Manager</li>
                            <li>🔜 Python Radiologist, an automated X-Ray Reader</li>
                            <li><button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#teleprompterModal">Launch HTML Quick-Teleprompter</button></li>
                        </ul>
                    </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                <div className="modal fade" id="teleprompterModal" tabIndex="-1" aria-labelledby="teleprompterModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="teleprompterModalLabel">Quick Teleprompter</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <iframe srcDoc={teleprompter.teleprompter} title="Teleprompter" id="ifr2" style={{width:"100%",height:"100%"}}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>

                {/* <div className="accordion-item">
                    <h2 className="accordion-header" style={{'--bs-primary-text-emphasis':'#FFFFFF'}}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        {textsArr[4][lang]}
                    </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                    <div className="accordion-body">
                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                    </div>
                </div> */}
            </div>
            <br/><br/>
            <div className="container text-center">
                <p>©{year} YHY/SPC LLC</p>
            </div>
        </div>
      );
}

export default App